.experience {
    width: 720px;
    margin: 40px auto;
    padding-block: 40px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* text-align: justify; */
}


.experience > * {
    margin-bottom: 20px;
}

.experience ul {
    /* text-align: center; */
    max-width: 340px;
    list-style-type: '- ';
    list-style-position: outside;
    word-break: keep-all;
    /* transform: translateX(10px); */
}

.experience li {
    margin-bottom: 10px;
}

.experienceImgWrap {
    width: 250px;
}

.experienceImgWrap img{
    width: 100%;
}

.moreInfo span {
    margin-inline: 20px;
}