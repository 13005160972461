.ProjectsContainer {
    /* width: 100%;
    max-width: 1200px;
    margin: auto; */
    /* padding-top: 40px; */
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}
.colorFiller {
    background-color: #303636;
    height: 100px;
}

.ProjectsContainer img{
    vertical-align: middle;
    width: 100%;
}

.Project {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Project:nth-child(even) {
    background-color: #f5f5f5;
}
.imgWrap {
    margin-top: 80px;
    margin-left: 200px;
    width: 40%;
    box-shadow: 0px -6px 10px #ccc;
    transition: .3s;
    transform: scale(1);
}

.imgWrap:hover {
    transform: scale(1.02);
    box-shadow: 0px -6px 20px #ccc;
}

.ProjectDescription {
    width: 220px;
}

.ProjectDescription > * {
    margin-bottom: 15px;
}

.ProjectDescription h2 {
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
}
.ProjectDescription ul {
    /* list-style-type: disc;
    list-style-position: inside; */
    list-style: disc inside;
}